@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@layer base {
  html {
    font-family: 'Montserrat', sans-serif;
    scroll-behavior: smooth;
  }
  table {
    @apply border-collapse border border-slate-500
  }
  td {
    @apply border border-slate-700 px-2
  }
}
.button {
  @apply text-base py-3 px-6 bg-black text-white rounded-tl-2xl rounded-br-2xl hover:bg-[#272727] transition-all
}
